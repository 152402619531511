<template>
  <page-content>
    <el-form
      ref="formRef"
      :model="state.info"
      :disabled="isDisabled"
      :rules="rules"
    >
      <div class="item-container">
        <p class="item-container-header">
          房屋名称
        </p>
        <el-form-item prop="roomName">
          <el-input v-model="state.info.roomName" />
        </el-form-item>
      </div>
      <div class="item-container">
        <p class="item-container-header">
          房主名称
        </p>
        <el-form-item prop="host">
          <el-input v-model="state.info.host" />
        </el-form-item>
      </div>
      <div class="item-container">
        <p class="item-container-header">
          房主电话
        </p>
        <el-form-item prop="mobile">
          <el-input v-model="state.info.mobile" />
        </el-form-item>
      </div>
      <div class="item-container">
        <p class="item-container-header">
          房屋地址
        </p>
        <el-form-item prop="adress">
          <el-input v-model="state.info.adress" type="textarea" />
        </el-form-item>
      </div>
      <div class="item-container">
        <p class="item-container-header">
          房屋介绍
        </p>
        <el-form-item prop="remark">
          <el-input v-model="state.info.remark" type="textarea" />
        </el-form-item>
      </div>
      <div class="item-container">
        <p class="item-container-header">
          房屋照片
        </p>
        <el-form-item prop="images">
          <upImage :disabled="isDisabled" :imageList.sync="state.info.images" type="string" num="10" />
        </el-form-item>
      </div>
    </el-form>
    <div class="item-footer-container">
      <el-button type="primary" @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </page-content>
</template>

<script setup>
import { reactive, onMounted, computed, ref } from 'vue'
import router from '@/router'
import axios from '@/http/axios'
import api from '@/http/api'
import upImage from '@/components/common/upImage/'
import { Message } from 'element-ui'

const state = reactive({
  info: {}
})

const rules = {
  roomName: [{ required: true, message: '请输入', trigger: 'blur' }],
  host: [{ required: true, message: '请输入', trigger: 'blur' }],
  mobile: [{ required: true, message: '请输入', trigger: 'blur' }],
  adress: [{ required: true, message: '请输入', trigger: 'blur' }],
  remark: [{ required: true, message: '请输入', trigger: 'blur' }],
  images: [{ required: true, message: '请输入', trigger: 'change' }],
}

const formRef = ref(null)

const isDisabled = computed(()=>{
  return router?.history?.current?.query?.type === 'view'
})

const id = computed(()=>{
  return router?.history?.current?.query?.id
})

function getInfo() {
  axios.get(api.lease.info, {
    params: {
      id: id.value
    }
  }).then((res)=>{
    state.info = res?.data ?? {}
  })
}

function cancel() {
  router.back()
}

function submit() {
  if (router?.history?.current?.query?.type === 'view') {
    return cancel()
  }
  formRef.value.validate((valid)=>{
    if (!valid) {
      return
    }
    let url = api.lease.edit
    if (router?.history?.current?.query?.type === 'add') {
      url = api.lease.add
    }
    axios.post(url, {
      ...state.info,
      type: 2,
    }).then((res)=>{
      if (res.code === 0) {
        Message({
          type: 'success',
          message: '操作成功!'
        });
        cancel()
      }
    })
  })
}

onMounted(()=>{
  if (id.value) {
    getInfo()
  }
}) 

</script>

<style lang="scss" scoped>

.item-container {
  :deep {
    .el-input__inner,  .el-textarea__inner {
      width: 440px;
    }
  }
  margin-bottom: 15px;
  .item-container-header {
    border-left: 3px solid #409eff;
    padding: 12px;
    font-size: 18px;
    margin: 20px 0;
    .tip {
      font-size: 12px;
      color: #999;
    }
  }
  .item-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .item-desc {
  }
  .item-image {
    margin-top: 15px;
    width: 200px;
    height: 200px;
  }
  .goods-container {
    display: flex;
    .goods-item-image {
      width: 100px;
      height: 100px;
    }
    .goods-item-info-container {
      margin-left: 15px;
      .goods-item-info-title {
        color: #1890FF;
        margin-bottom: 15px;
      }
      .goods-item-info-price {
        color:#D9001B;
        margin-top: 15px;
      }
    }
  }
  .set-item-container {
    margin-bottom: 15px;
    display: flex;
    :nth-child(1) {
      margin-right: 20px;
    }
  }
}
.item-footer-container {
  display: flex;
  justify-content: center;
}

</style>
