var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "page-content",
    [
      _c(
        "el-form",
        {
          ref: "formRef",
          attrs: {
            model: _setup.state.info,
            disabled: _setup.isDisabled,
            rules: _setup.rules,
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房屋名称 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "roomName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _setup.state.info.roomName,
                      callback: function ($$v) {
                        _vm.$set(_setup.state.info, "roomName", $$v)
                      },
                      expression: "state.info.roomName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房主名称 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "host" } },
                [
                  _c("el-input", {
                    model: {
                      value: _setup.state.info.host,
                      callback: function ($$v) {
                        _vm.$set(_setup.state.info, "host", $$v)
                      },
                      expression: "state.info.host",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房主电话 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "mobile" } },
                [
                  _c("el-input", {
                    model: {
                      value: _setup.state.info.mobile,
                      callback: function ($$v) {
                        _vm.$set(_setup.state.info, "mobile", $$v)
                      },
                      expression: "state.info.mobile",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房屋地址 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "adress" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _setup.state.info.adress,
                      callback: function ($$v) {
                        _vm.$set(_setup.state.info, "adress", $$v)
                      },
                      expression: "state.info.adress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房屋介绍 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _setup.state.info.remark,
                      callback: function ($$v) {
                        _vm.$set(_setup.state.info, "remark", $$v)
                      },
                      expression: "state.info.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item-container" },
            [
              _c("p", { staticClass: "item-container-header" }, [
                _vm._v(" 房屋照片 "),
              ]),
              _c(
                "el-form-item",
                { attrs: { prop: "images" } },
                [
                  _c(_setup.upImage, {
                    attrs: {
                      disabled: _setup.isDisabled,
                      imageList: _setup.state.info.images,
                      type: "string",
                      num: "10",
                    },
                    on: {
                      "update:imageList": function ($event) {
                        return _vm.$set(_setup.state.info, "images", $event)
                      },
                      "update:image-list": function ($event) {
                        return _vm.$set(_setup.state.info, "images", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "item-footer-container" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _setup.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _setup.submit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }